import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";
import IMG6 from "../../assets/portfolio6.jpg";
import IMG7 from "../../assets/portfolio0.jpg";
import IMG8 from "../../assets/portfolio7.jpg";

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 7,
    image: IMG7,
    title: "ChatGPT & DAll-e Client",
    subtitle:
      "The app is just a side project that makes me access ChatGPT in a restricted region more easily",
    demo: "https://david-ng.dev/chatgpt/",
  },
  {
    id: 8,
    image: IMG8,
    title: "CryptoEye Website (In Development)",
    subtitle:
      "A website for monitoring key indicators in the crypto market. It includes dynamic charts and real-time performance indicator provided by well-known API",
    demo: "https://david-ng.dev/cds/",
  },
  {
    id: 1,
    image: IMG1,
    title: "Scanner360",
    subtitle:
      "All-in-one mobile scanner app that allows you to add password, e-sign, stamp and much more.",
    demo: "https://apps.apple.com/us/app/scanner-360-document-scanner/id1169437953",
  },
  {
    id: 2,
    image: IMG2,
    title: "Legal Agreement Clause",
    subtitle:
      "The best contract clauses library in your pocket. It includes over 4000 clauses with 40+ categories.",
    demo: "https://apps.apple.com/us/app/legal-agreement-clause/id1143003899",
  },
  {
    id: 3,
    image: IMG3,
    title: "Nextstep Corporate Website",
    subtitle:
      "The WordPress website that is deployed by me. It is about a food and beverage services company that based in Hong Kong.",
    demo: "https://nextstep.com.hk/",
  },
  {
    id: 5,
    image: IMG5,
    title: "Lo Lau Lawyers website",
    subtitle:
      "The WordPress website that is designed and deployed by me. I am experienced in making Law Firm Website with multiple languages. Analytics and SEO are also added to this site.",
    demo: "https://lolaulawyers.com/",
  },
  {
    id: 6,
    image: IMG6,
    title: "HKU Lawlypop",
    subtitle:
      "This is a YouTube channel that is completely built by me. From channel graphic design, to video editing are all managed by me.",
    demo: "https://www.youtube.com/c/HKULawlypop1/featured",
  },
  {
    id: 4,
    image: IMG4,
    title: "SmartMoney (In Development)",
    subtitle:
      "A flutter application for money management built by me. The UI adopts the latest Material You design framework.",
    demo: "https://play.google.com/store/apps/details?id=com.davidng.smart_money",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, subtitle, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <h5>{subtitle}</h5>
              <div className="portfolio__item-cta">
                <a href={demo} className="btn" target="_blank">
                  VIEW MORE
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
