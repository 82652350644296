import { useState, useRef } from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
// import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const SERVICE_ID = "service_11nm9ts";
  const TEMPLATE_ID = "template_yjr3614";
  const PUBLIC_KEY = "p-rWf-IKjdq_cIr-d";

  const [buttonText, setButtonText] = useState("Send Message");
  const [status, setStatus] = useState({});

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        setStatus({ success: true, message: "Message sent successfully" });
        setButtonText("Send Message");
        document.getElementById("email-form").reset();
      },
      (error) => {
        setStatus({
          success: false,
          message: "Something went wrong, please try again later.",
        });
        setButtonText("Send Message");
      }
    );
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>ngcjgbir8w@gmail.com</h5>
            <a href="mailto:ngcjgbir8w@gmail.com" target="_blank">
              Send a message
            </a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+852 9156 3618</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+85291563618"
              target="_blank"
            >
              Send a message
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail} id="email-form">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          {/* <div
            class="g-recaptcha"
            data-sitekey="6LeSTb4gAAAAAEk101VpL1KFNrgVAlK_nF9HbyDO"
          ></div>
          <br /> */}
          <button type="submit" className="btn btn-primary">
            {buttonText}
          </button>
          <br />
          <p>
            {status.message && (
              <p className={status.success === false ? "danger" : "success"}>
                {status.message}
              </p>
            )}
          </p>
        </form>
      </div>
    </section>
  );
};

export default Contact;
