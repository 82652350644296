import React from "react";
// import CV from '../../assets/cv.pdf'

const CTA = () => {
  return (
    <div className="cta">
      <a href="#services" className="btn">
        MY SERVICE
      </a>
      <a href="#contact" className="btn btn-primary">
        CONTACT ME
      </a>
    </div>
  );
};

export default CTA;
