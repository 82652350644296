import React from "react";
import "./services.css";
import WPP from "../../assets/WordPress-Proposal.pdf";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>
              WordPress
              <br />
              Web Development
            </h3>
          </div>
          <a
            href={WPP}
            className="btn btn-primary"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
            target="_blank"
          >
            DOWNLOAD BROCHURE
          </a>

          <ul className="service__list">
            <li>
              <p>
                <b>Features</b>
                <ul className="service__sublist">
                  <li>Modern &amp; Responsive website</li>
                  <li>Free SSL installation</li>
                  <li>Speed optimised with analytics</li>
                  <li>Social media integration</li>
                  <li>Photography website</li>
                  <li>E-commerce ready</li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <b>Specialisation</b>
                <ul className="service__sublist">
                  <li>Blog, business &amp; corporate website</li>
                  <li>Education website</li>
                  <li>Personal portfolio</li>
                  <li>Online communities</li>
                  <li>Photography website</li>
                  <li>Online store</li>
                </ul>
              </p>
            </li>
            <li>
              <p>Delivery time: 3 to 12 days</p>
            </li>
            <li>
              <p>Up to 5 revisions</p>
            </li>
            <li>
              <p>7-day maintenance and support</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>
              React
              <br />
              Web Development
            </h3>
          </div>

          <a
            href="#contact"
            className="btn btn-primary"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
          >
            ASK FOR QUOTATION
          </a>

          <ul className="service__list">
            <li>
              <p>
                <b>Features</b>
                <ul className="service__sublist">
                  <li>Interactive, modern and responsive website</li>
                  <li>Login and register system</li>
                  <li>Paypal and stripe integration</li>
                  <li>Page with dynamic content</li>
                  <li>Multilingual support</li>
                  <li>Web app integration (Android &amp; iOS)</li>
                  <li>Web3 &amp; Solana integration</li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <b>Specialisation</b>
                <ul className="service__sublist">
                  <li>Membership website</li>
                  <li>Office portal website</li>
                  <li>NFT website (Solana / Web3)</li>
                </ul>
              </p>
            </li>
            <li>
              <p>Project time: At least 1 month (Depends on complexity)</p>
            </li>
            <li>
              <p>Contact us and ask for quotation</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>
              Native iOS App
              <br />
              Development
            </h3>
          </div>

          <a
            href="#contact"
            className="btn btn-primary"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
          >
            ASK FOR QUOTATION
          </a>

          <ul className="service__list">
            <li>
              <p>
                <b>Features</b>
                <ul className="service__sublist">
                  <li>
                    Modern UI for iPhone &amp; iPad using SwiftUI framework
                  </li>
                  <li>UI &amp; UX design</li>
                  <li>Frontend &amp; Backend integration</li>
                  <li>Google analytics enabled</li>
                  <li>*App store listing</li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <b>Specialisation</b>
                <ul className="service__sublist">
                  <li>Utility apps</li>
                  <li>Company portal apps</li>
                  <li>Membership apps</li>
                  <li>E-commerce apps</li>
                </ul>
              </p>
            </li>
            <li>
              <p>Project time: At least 2 months (Depends on complexity)</p>
            </li>
            <li>
              <p>Contact us and ask for quotation</p>
            </li>
          </ul>
        </article>
        {/* TEACHING */}
        <article className="service">
          <div className="service__head">
            <h3>
              Online &amp; Offline
              <br />
              Coding Courses
            </h3>
          </div>

          <a
            href="#contact"
            className="btn btn-primary"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
          >
            COLLABORATION
          </a>

          <ul className="service__list">
            <li>
              <p>Age: Grade 4 to 12</p>
            </li>
            <li>
              <p>
                <b>Content</b>
                <ul className="service__sublist">
                  <li>Swift playground</li>
                  <li>Scratch &amp; App Inventor</li>
                  <li>Python development</li>
                  <li>SwiftUI &amp; iOS development</li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <b>Course types</b>
                <ul className="service__sublist">
                  <li>Offline (HK only)</li>
                  <li>Online (Zoom/ Skype/ Google Meet)</li>
                </ul>
              </p>
            </li>
          </ul>
        </article>

        {/* VIDEO EDITING */}
        <article className="service">
          <div className="service__head">
            <h3>
              YouTube, Instagram
              <br />
              Video Editing
            </h3>
          </div>

          <a
            href="#contact"
            className="btn btn-primary"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
          >
            COLLABORATION
          </a>

          <ul className="service__list">
            <li>
              <p>Edit short to medium YouTube &amp; Instagram video</p>
            </li>
            <li>
              <p>Manage YouTube channel</p>
            </li>
            <li>
              <p>
                <b>Specialisation</b>
                <ul className="service__sublist">
                  <li>Video interview</li>
                  <li>Vlogs &amp; Travel video</li>
                  <li>Education videos</li>
                  <li>Fun &amp; commedy video</li>
                </ul>
              </p>
            </li>
            <li>
              <p>Contact us and ask for quotation</p>
            </li>
          </ul>
        </article>

        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  );
};

export default Services;
